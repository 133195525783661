import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {TextField} from "@mui/material";

export default function NumberInput({label, value, setValue}) {
    return (
        <div>
            <FormControl
                fullWidth={true}
                sx={{
                    m: 1,
                    width: "25rem",
                }}
            >
                <TextField
                    onChange={(e) => setValue(e.target.value)}
                    label={label}
                    type="number"
                    inputProps={{type: 'number'}}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                />
            </FormControl>
        </div>
    )
}