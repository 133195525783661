import React, {useEffect, useState} from 'react';
import Navbar from "../../../components/navbar/Navbar";
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import {FilterAlt, Search} from "@mui/icons-material";

import './index.css';
import EventsList from "../../../components/EventsList";
import {
    useGetAllEventsQuery,
    useGetAllGendersQuery,
    useGetAllLanguagesQuery,
    useGetAllRetreatTypesQuery,
} from "../../../store/emptySplitApi";
import MultipleSelectMenu from "../../../components/MultipleSelectMenu";
import NumberInput from "../../../components/NumberInput";

export const iconStyle = {color: "#111827", fontSize: "1.3rem"};

function AllEventsPage() {
    const [filters, setFilters] = useState({});

    const {data: events = [], isFetching, isUninitialized, refetch} = useGetAllEventsQuery(filters, {
        refetchOnReconnect: true,
    })

    const {data: genders = []} = useGetAllGendersQuery()
    const {data: languages = []} = useGetAllLanguagesQuery()
    const {data: retreatTypes = []} = useGetAllRetreatTypesQuery()

    const [selectedRetreatTypes, setSelectedRetreatTypes] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [minCost, setMinCost] = useState(0);
    const [maxCost, setMaxCost] = useState(0);
    const [minCostForHousing, setMinCostForHousing] = useState(0);
    const [maxCostForHousing, setMaxCostForHousing] = useState(0);

    const [search, setSearch] = useState('');

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    const handleFiltersSubmit = (e) => {
        const filters = {
            retreatTypes: typeof selectedRetreatTypes === 'string' ? [selectedRetreatTypes] : selectedRetreatTypes.join(','),
            languages: typeof selectedLanguages === 'string' ? [selectedLanguages] : selectedLanguages.join(','),
            genders: typeof selectedGenders === 'string' ? [selectedGenders] : selectedGenders.join(','),
            minCost: minCost.toString(),
            maxCost: maxCost.toString(),
            minCostForHousing: minCostForHousing.toString(),
            maxCostForHousing: maxCostForHousing.toString(),
        };

        Object.keys(filters).forEach(key => {
            if (!filters[key] || filters[key] === '0' || filters[key] === "") {
                delete filters[key];
            }
        });

        setFilters(prev => filters);
        refetch();
    }

    return (
        <>
            <Navbar title="Events" child={
                <TextField
                    label="Seach events"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <Search sx={iconStyle}/>
                        )
                    }}
                />
            }/>
            <div className="content-container">
                <div className="filter-bar">
                    <MultipleSelectMenu label={"Languages"} values={languages} selectedValues={selectedLanguages}
                                        setSelectedValues={setSelectedLanguages}/>
                    <MultipleSelectMenu label={"Genders"} values={genders} selectedValues={selectedGenders}
                                        setSelectedValues={setSelectedGenders}/>
                    <MultipleSelectMenu label={"Retreat Types"} values={retreatTypes}
                                        selectedValues={selectedRetreatTypes}
                                        setSelectedValues={setSelectedRetreatTypes}/>
                    <NumberInput label={"Min Cost, €"} value={minCost} setValue={setMinCost}/>
                    <NumberInput label={"Max Cost, €"} value={maxCost} setValue={setMaxCost}/>
                    <NumberInput label={"Min Housing Cost, €"} value={minCostForHousing} setValue={setMinCostForHousing}/>
                    <NumberInput label={"Max Housing Cost, €"} value={maxCostForHousing} setValue={setMaxCostForHousing}/>
                    <Box
                        sx={{
                            m: 1,
                            // maxWidth: "22%",
                            width: "25rem",
                        }}
                    >
                        <Button variant="contained" startIcon={<FilterAlt/>}
                                onClick={handleFiltersSubmit}
                                sx={{
                                    backgroundColor: "#BA9568",
                                    padding: "0.6rem",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    "&:hover": {backgroundColor: "#836136",},
                                }}
                        >
                            Apply Filters
                        </Button>
                    </Box>
                </div>
                {isFetching ? (
                    <Box className="loading-bar">
                        <CircularProgress/>
                    </Box>
                ) : (
                    <EventsList eventValues={events} search={search}/>
                )}
            </div>
        </>
    );
}

export default AllEventsPage;