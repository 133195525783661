import React from 'react';
import "./Navbar.css"
import {IconButton} from "@mui/material";
import {ArrowBack} from '@mui/icons-material';
import {useNavigate} from "react-router-dom";

const Navbar = ({title, child}) => {
    const navigate = useNavigate();
    const goBack = (event) => {
        event.preventDefault();
        navigate(-1);
    };

    return (
        <div className="wrapper">
            <div className="navbar-container-title">
                <IconButton size="large" onClick={goBack}>
                    <ArrowBack/>
                </IconButton>
                <h1 className="navbar-title">
                    {title}
                </h1>
            </div>
            {child}
        </div>
    );
};

export default Navbar;