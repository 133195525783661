import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {DatePicker} from 'antd';
import {useSelector} from "react-redux";
import dayjs from "dayjs";

import Navbar from "../../../components/navbar/Navbar";
import StyledFormBox from "../../../components/StyledFormBox";
import StyledInputWithoutForm from "../../../components/StyledInputWithoutForm";
import MultipleSelectMenu from "../../../components/MultipleSelectMenu";
import {
    useCreateEventMutation,
    useGetAllGendersQuery,
    useGetAllLanguagesQuery,
    useGetAllRetreatTypesQuery,
    useGetMyFacilityQuery
} from "../../../store/emptySplitApi";

const errorMessages = {
    title: "Title is required.",
    address: "Address is required.",
    startDate: "Start date is required.",
    endDate: "End date is required.",
    retreatTypes: "Retreat Type is required",
    cost: "Cost is required and more or equal to 0.",
    languages: "Languages is required field.",
    genders: "Genders is required field.",
    facilitators: "Facility where the event is conducted is required. Only one should be chosen",
}

function CreateEventPage(props) {
    const {data: genders = []} = useGetAllGendersQuery()
    const {data: languages = []} = useGetAllLanguagesQuery()
    const {data: retreatTypes = []} = useGetAllRetreatTypesQuery()

    const {role: userRole, id: userId} = useSelector(state => state.auth.user.user);

    const {data: myFacilities = [], isFetching, refetch, isUninitialized} = useGetMyFacilityQuery(userId, {
        skip: userRole !== "Admin" || !userId,
    });

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    const [createEvent, {error: mutationError}] = useCreateEventMutation();

    const [selectedRetreatTypes, setSelectedRetreatTypes] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedFacilitators, setSelectedFacilitators] = useState([]);

    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [cost, setCost] = useState(0);
    const [costForHousing, setCostForHousing] = useState(0);
    const [link, setLink] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");

    const [generalError, setGeneralError] = React.useState(null);
    const [message, setMessage] = React.useState(null);

    const [errors, setErrors] = useState({
        title: false,
        address: false,
        startDate: false,
        endDate: false,
        retreatTypes: false,
        cost: false,
        languages: false,
        genders: false,
        facilitators: false,
    });

    const validateForm = () => {
        const newErrors = {
            title: !title || title === "",
            address: !address || address === "",
            startDate: !startDate || startDate === "",
            endDate: !endDate || endDate === "",
            retreatTypes: selectedRetreatTypes.length === 0,
            cost: cost === undefined || cost < 0 || cost === "",
            languages: selectedLanguages.length === 0,
            genders: selectedGenders.length === 0,
            facilitators: (selectedFacilitators.length === 0 || selectedFacilitators.length > 1)
                && userRole !== "FacilityLeader",
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };

    const handleInputChange = setter => event => {
        setter(event.target.value);
    };

    const handleNumericChange = setter => event => {
        const value = parseInt(event.target.value, 10);
        setter(!isNaN(value) ? value : '');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = {
            title,
            startDate,
            endDate,
            cost,
            costForHousing,
            link,
            description,
            address,
            retreatTypes: selectedRetreatTypes,
            languages: selectedLanguages,
            genders: selectedGenders,
            facilitator: userRole === "FacilityLeader" ? userId : selectedFacilitators[0],
        };

        try {
            const createEventData = await createEvent(formData);
            if (createEventData.error) {
                console.log(`error: ${JSON.stringify(createEventData.error)}`)
                setGeneralError(createEventData.error.data.message);
                setMessage(null);
            } else {
                setGeneralError(null);
                setMessage('Event created successfully!');
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    };

    const renderErrorMessage = (fieldName) => {
        if (errors[fieldName]) {
            return <Box
                sx={{
                    color: "#db4f4a",
                    marginLeft: "2rem",
                }}
            >
                {errorMessages[fieldName]}
            </Box>;
        }
        return null;
    };


    return (
        <>
            <Navbar title="Create Event Page"/>
            <StyledFormBox>
                {/* Attach onChange and value to each StyledInputWithoutForm */}
                <StyledInputWithoutForm
                    label="Event Title"
                    value={title}
                    onChange={handleInputChange(setTitle)}
                    errorMessage={renderErrorMessage("title")}
                />
                <StyledInputWithoutForm
                    label="Cost"
                    value={cost}
                    type="number"
                    inputProps={{type: 'number'}}
                    onChange={handleNumericChange(setCost)}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    errorMessage={renderErrorMessage("cost")}
                />
                <StyledInputWithoutForm
                    label="Cost For Housing"
                    value={costForHousing}
                    type="number"
                    inputProps={{type: 'number'}}
                    onChange={handleNumericChange(setCostForHousing)}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                />
                <StyledInputWithoutForm
                    label="Link"
                    value={link}
                    onChange={handleInputChange(setLink)}
                />
                <StyledInputWithoutForm
                    label="Description"
                    value={description}
                    onChange={handleInputChange(setDescription)}
                />
                <StyledInputWithoutForm
                    label="Address"
                    value={address}
                    onChange={handleInputChange(setAddress)}
                    errorMessage={renderErrorMessage("address")}
                />
                <MultipleSelectMenu
                    label={"Retreat Types"}
                    values={retreatTypes}
                    selectedValues={selectedRetreatTypes}
                    setSelectedValues={setSelectedRetreatTypes}
                />
                {renderErrorMessage("retreatTypes")}
                <MultipleSelectMenu
                    label={"Languages"}
                    values={languages}
                    selectedValues={selectedLanguages}
                    setSelectedValues={setSelectedLanguages}
                />
                {renderErrorMessage("languages")}
                <MultipleSelectMenu
                    label={"Genders"}
                    values={genders}
                    selectedValues={selectedGenders}
                    setSelectedValues={setSelectedGenders}
                />
                {renderErrorMessage("genders")}
                {userRole === "Admin" && (
                    <MultipleSelectMenu
                        label={"Facility conducting the event"}
                        values={myFacilities}
                        selectedValues={selectedFacilitators}
                        setSelectedValues={setSelectedFacilitators}
                        isFacilityRender={true}
                    />
                )}
                {renderErrorMessage("facilitators")}
                <Box
                    sx={{
                        // width: "45%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        margin: "1rem",
                    }}
                >

                    <Typography
                        sx={{
                            ml: "0.3rem",
                            mb: "0.3rem",
                            fontSize: "1.2rem",
                        }}
                    >
                        Start and End dates
                    </Typography>
                    <DatePicker.RangePicker
                        size="large"
                        format={'DD-MM-YYYY'}
                        onChange={(date, dateString) => {
                            setStartDate(prev => dayjs(dateString[0], "DD-MM-YYYY").toISOString())
                            setEndDate(prev => dayjs(dateString[1], "DD-MM-YYYY").toISOString())
                        }}
                    />
                </Box>
                {renderErrorMessage("startDate")}
                {renderErrorMessage("endDate")}
                <Box display="flex" justifyContent="end" mt="20px">
                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained"
                        sx={{fontWeight: "600"}}
                    >
                        Create
                    </Button>
                </Box>

                {message && (
                    <Typography
                        variant="h4"
                        color="#4cceac"
                        mt="15px"
                    >
                        {message}
                    </Typography>
                )}
                {generalError && (
                    <Typography
                        variant="h4"
                        color="#db4f4a"
                        mt="15px"
                    >
                        {generalError}
                    </Typography>
                )}
            </StyledFormBox>
        </>
    )
}

export default CreateEventPage;