import React from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import {divIcon, Icon, point} from 'leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import './../../styles.css';
import './MapView.css';
import "leaflet/dist/leaflet.css";
import {Link} from "react-router-dom";

const center = [51.1657, 10.4515];

const customMarkerIcon = new Icon({
    iconUrl: require("../../icons/location-pin.png"),
    iconSize: [64, 64],
})

const createClusterCustomIcon = (cluster) => {
    return new divIcon({
        html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconSize: point(33, 33, true)
    });
};

const Markers = ({facilitiesData}) => {
    return facilitiesData?.map((facility, index) => {
        if (!facility) return null;
        return (<Marker
            key={facility._id}
            position={{lat: facility.latitude, lng: facility.longitude}}
            icon={customMarkerIcon}
        >
            <Popup>
                <h1 className="map-popup__title">{facility?.title}</h1>
                <div className="facility-info">
                    <div className="facility-info__item">
                        <span className="facility-info__value">Address: {facility.address}</span>
                    </div>
                    <div className="facility-info__item">
                        <span className="facility-info__value">Donation Based: {facility.donationBased ? "Yes" : "No"}</span>
                    </div>
                    <div className="facility-info__item">
                        <span className="facility-info__value">Bookable for External Retreat Leaders: {facility.bookable ? "Yes" : "No"}</span>
                    </div>
                    <div className="facility-info__item">
                        <span className="facility-info__value">Cost For Housing: {facility.costForHousing ? facility.costForHousing + "€" : "Unknown"}</span>
                    </div>
                    <div className="facility-info__item">
                        <span className="facility-info__value">Facility Type:</span>
                        <ul className="facility-details-map__item__info array">

                            {facility.facilityTypes?.map((item, index) => (
                                <li className="facility-info__value" key={item._id}>{item.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="facility-info__item">
                        <span className="facility-info__value">Denominations:</span>
                        <ul className="facility-details-map__item__info array">

                            {facility.denominations?.map((item, index) => (
                                // Consider using a unique identifier from item if possible
                                <li className="facility-info__value" key={item._id}>{item.name}</li>
                            ))}
                        </ul>
                    </div>
                    {facility.genders && facility.genders.length !== 0 && (<div className="facility-info__item">
                        <span className="facility-info__value">Genders:</span>
                        <ul className="facility-details-map__item__info array">

                            {facility.genders?.map((item, index) => (
                                <li className="facility-info__value" key={item._id}>{item.name}</li>
                            ))}
                        </ul>
                    </div>)}
                    <div className="facility-info__item">
                    <Link to={`/facility/${facility._id}`} className="facility-info__link">
                            <span className="facility-info__value">{facility.title}</span>
                        </Link>
                    </div>
                </div>
            </Popup>
        </Marker>);
    })
};

function MapView({facilitiesData}) {
    return (
        <MapContainer center={center} zoom={6}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup
                chunckedLoading
                iconCreateFunction={createClusterCustomIcon}
            >
                <Markers facilitiesData={facilitiesData}/>
            </MarkerClusterGroup>
        </MapContainer>
    );
}

export default MapView;