import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: '/server/api/',
    // baseUrl: 'http://localhost:5000/api/',
    prepareHeaders: (headers, {getState, endpoint}) => {
        if (['login', 'refresh'].includes(endpoint)) {
            return headers;
        }

        const token = getState().auth?.user?.accessToken;

        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
})

export const api = createApi({
    reducerPath: 'api',
    tagTypes: [
        "Events",
        "Facilities",
    ],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        logout: builder.mutation({
            query: (body) => ({
                url: 'auth/logout',
                method: 'POST',
                body,
            })
        }),
        register: builder.mutation({
            query: (body) => ({
                url: 'auth/register',
                method: 'POST',
                body: body
            })
        }),
        getMe: builder.query({
            query: () => 'auth/me'
        }),

        //     User CRUD
        getUser: builder.query({
            query: (id) => `user/${id}`
        }),
        getUsers: builder.query({
            query: () => 'user',
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Users', id})),
                    {type: 'Users', id: 'LIST'},
                ]
                : [{type: 'Users', id: 'LIST'}],
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: 'user',
                method: 'POST',
                body
            }),
            invalidatesTags: [{type: 'Users', id: 'LIST'}]
        }),
        updateUser: builder.mutation({
            query: ({id, body}) => ({
                url: `user/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: [{type: 'Users', id: 'LIST'}]
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'Users', id: 'LIST'}]
        }),
        //     Facility CRUD
        getAllFacilities: builder.query({
            query: (filters) => {
                const queryString = Object.keys(filters)
                    .filter(key => filters[key] !== undefined && filters[key] !== '')
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
                    .join('&');

                return `facility/?${queryString}`;
            }
        }),
        getFacilityById: builder.query({
            query: (facilityId) => `facility/${facilityId}`
        }),
        getMyFacility: builder.query({
            query: (userId) => `facility/user/${userId}`
        }),
        createFacility: builder.mutation({
            query: (facilityData) => ({
                url: 'facility',
                method: 'POST',
                body: facilityData
            }),
            invalidatesTags: [{type: 'Facilities', id: 'LIST'}]
        }),
        updateFacility: builder.mutation({
            query: ({facilityId, facilityData}) => ({
                url: `facility/${facilityId}`,
                method: 'PUT',
                body: facilityData
            }),
            invalidatesTags: [{type: 'Facilities', id: 'LIST'}]
        }),
        deleteFacility: builder.mutation({
            query: (facilityId) => ({
                url: `facility/${facilityId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'Facilities', id: 'LIST'}]
        }),

        //     Event CRUD
        getAllEvents: builder.query({
            query: (filters) => {
                const queryString = Object.keys(filters)
                    .filter(key => filters[key] !== undefined && filters[key] !== '')
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
                    .join('&');

                return `event/?${queryString}`;
            }
        }),
        getEventById: builder.query({
            query: (eventId) => `event/${eventId}`
        }),
        getMyEvents: builder.query({
            query: (userId) => `event/user/${userId}`
        }),
        createEvent: builder.mutation({
            query: (eventData) => ({
                url: 'event',
                method: 'POST',
                body: eventData
            }),
            invalidatesTags: [{type: 'Events', id: 'LIST'}]
        }),
        updateEvent: builder.mutation({
            query: ({eventId, eventData}) => ({
                url: `event/${eventId}`,
                method: 'PUT',
                body: eventData
            }),
            invalidatesTags: [{type: 'Events', id: 'LIST'}]
        }),
        deleteEvent: builder.mutation({
            query: (eventId) => ({
                url: `event/${eventId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'Events', id: 'LIST'}]
        }),
    //     Utility Get
        getAllGenders: builder.query({
            query: () => 'utility/getAllGenders'
        }),
        getAllLanguages: builder.query({
            query: () => 'utility/getAllLanguages'
        }),
        getAllRetreatTypes: builder.query({
            query: () => 'utility/getAllRetreatTypes'
        }),
        getAllDenominations: builder.query({
            query: () => 'utility/getAllDenominations'
        }),
        getAllFacilityTypes: builder.query({
            query: () => 'utility/getAllFacilityTypes'
        }),

    }),
})

export const {
    useLoginMutation,
    useRefreshMutation,
    useLogoutMutation,
    useRegisterMutation,
    useGetMeQuery,
//     User CRUD
    useGetUserQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
//     Facility CRUD
    useGetAllFacilitiesQuery,
    useGetFacilityByIdQuery,
    useGetMyFacilityQuery,
    useCreateFacilityMutation,
    useUpdateFacilityMutation,
    useDeleteFacilityMutation,
//     Event CRUD
    useGetAllEventsQuery,
    useGetEventByIdQuery,
    useGetMyEventsQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
//     Utility Get
    useGetAllGendersQuery,
    useGetAllLanguagesQuery,
    useGetAllRetreatTypesQuery,
    useGetAllDenominationsQuery,
    useGetAllFacilityTypesQuery,
} = api;