import Navbar from "../../../components/navbar/Navbar";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const PrivacyPolicyPage = (props) => {
    return (
        <div className="map-main-container">
            <Navbar title="Privacy Policy Page"/>

            <Box
                className="content-container"
                sx={{
                    maxWidth: "70%",
                    "& .MuiTypography-root": {
                        mt: "1rem",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                    },
                    "& .MuiTypography-h4": {
                        mt: "3rem",
                        fontSize: "2.1rem",
                        fontWeight: "600",
                    },
                    "& .MuiTypography-h5": {
                        mt: "3rem",
                        fontSize: "1.8rem",
                        fontWeight: "600",
                    },
                    "& .MuiTypography-h6": {
                        mt: "2rem",
                        fontSize: "1.5rem",
                        fontWeight: "600",
                    }
                }}
            >
                <Typography variant="h4">
                    PRIVACY POLICY
                </Typography>

                <Typography variant="h5">
                    Last updated March 28, 2024
                </Typography>

                <Typography variant="body1">
                    This privacy notice for Peter Knirsch Einzelunternehmen ("we," "us," or "our"),
                    describes how and why we might collect, store, use, and/or share ("process") your
                    information when you use our services ("Services"), such as when you:
                    Visit our website at monasteries.de, or any website of ours that links to this
                    privacy notice
                </Typography>

                <Typography variant="body1">
                    Engage with us in other related ways, including any sales, marketing, or
                    events
                </Typography>
                <Typography variant="body1">
                    Questions or concerns? <br/> Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our policies and practices, please
                    do not use our Services. If you still have any questions or concerns, please contact
                    us at peterjknirsch@gmail.com.
                </Typography>

                <Typography variant="h6">
                    SUMMARY OF KEY POINTS
                </Typography>
                <Typography variant="body1">
                    This summary provides key points from our privacy notice, but you can find
                    out more details about any of these topics by clicking the link following each
                    key point or by using our table of contents below to find the section you are
                    looking for.
                </Typography>
                <Typography variant="body1">
                    What personal information do we process? <br/> When you visit, use, or navigate our
                    Services, we may process personal information depending on how you interact with
                    us and the Services, the choices you make, and the products and features you use.
                    Learn more about personal information you disclose to us.
                </Typography>
                <Typography variant="body1">
                    Do we process any sensitive personal information? <br/> We may process sensitive
                    personal information when necessary with your consent or as otherwise permitted by
                    applicable law. Learn more about sensitive information we process.
                </Typography>
                <Typography variant="body1">
                    Do we receive any information from third parties? <br/> We do not receive any
                    information from third parties.
                </Typography>
                <Typography variant="body1">
                    How do we process your information? <br/> We process your information to provide,
                    improve, and administer our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your information for other
                    purposes with your consent. We process your information only when we have a valid
                    legal reason to do so. Learn more about how we process your information.
                </Typography>
                <Typography variant="body1">
                    In what situations and with which parties do we share personal information? <br/> We may share
                    information in specific situations and with specific third parties. Learn
                    more about when and with whom we share your personal information.
                </Typography>
                <Typography variant="body1">
                    How do we keep your information safe? We have organizational and technical
                    processes and procedures in place to protect your personal information. However, no
                    electronic transmission over the internet or information storage technology can be guaranteed to be
                    100%
                    secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
                    parties will not be able to defeat our security and improperly collect, access, steal, or modify
                    your
                    information. Learn more about how we keep your information safe. What are your rights? Depending on
                    where you are located geographically, the applicable privacy law may mean you have certain rights
                    regarding your personal information. Learn more about your privacy rights. How do you exercise your
                    rights? The easiest way to exercise your rights is by submitting a data subject access request, or
                    by
                    contacting us. We will consider and act upon any request in accordance with applicable data
                    protection
                    laws. Want to learn more about what we do with any information we collect? Review the privacy notice
                    in
                    full.
                </Typography>

                <Typography variant="body1">
                    TABLE OF CONTENTS<br/>
                    1. WHAT INFORMATION DO WE COLLECT?<br/>
                    2. HOW DO WE PROCESS YOUR INFORMATION?<br/>
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?<br/>
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?<br/>
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br/>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?<br/>
                    7. HOW LONG DO WE KEEP YOUR INFORMATION?<br/>
                    8. HOW DO WE KEEP YOUR INFORMATION SAFE?<br/>
                    9. DO WE COLLECT INFORMATION FROM MINORS?<br/>
                    10. WHAT ARE YOUR PRIVACY RIGHTS?<br/>
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES<br/>
                    12. DO WE MAKE UPDATES TO THIS NOTICE?<br/>
                    13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?<br/>
                    14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?<br/>
                </Typography>

                <Typography variant="h6">
                    1. WHAT INFORMATION DO WE COLLECT?
                </Typography>
                <Typography variant="body1">
                    Personal information you disclose to us In Short: We collect personal information that you provide
                    to
                    us. We collect personal information that you voluntarily provide to us when you register on the
                    Services, express an interest in obtaining information about us or our products and Services, when
                    you
                    participate in activities on the Services, or otherwise when you contact us. Personal Information
                    Provided by You. The personal information that we collect depends on the context of your
                    interactions
                    with us and the Services, the choices you make, and the products and features you use. The personal
                    information we collect may include the following: names phone numbers email addresses mailing
                    addresses
                    passwords usernames contact preferences Sensitive Information. When necessary, with your consent or
                    as
                    otherwise permitted by applicable law, we process the following categories of sensitive information:
                    information revealing religious or philosophical beliefs Social Media Login Data. We may provide you
                    with the option to register with us using your existing social media account details, like your
                    Facebook, Twitter, or other social media account. If you choose to register in this way, we will
                    collect
                    the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below. All
                    personal information that you provide to us must be true, complete, and accurate, and you must
                    notify us
                    of any changes to such personal information.
                    <br/>
                    Information automatically collected In Short:
                    Some information — such as your Internet Protocol (IP) address and/or browser and device
                    characteristics
                    — is collected automatically when you visit our Services. We automatically collect certain
                    information
                    when you visit, use, or navigate the Services. This information does not reveal your specific
                    identity
                    (like your name or contact information) but may include device and usage information, such as your
                    IP
                    address, browser and device characteristics, operating system, language preferences, referring URLs,
                    device name, country, location, information about how and when you use our Services, and other
                    technical
                    information. This information is primarily needed to maintain the security and operation of our
                    Services, and for our internal analytics and reporting purposes. Like many businesses, we also
                    collect
                    information through cookies and similar technologies. The information we collect includes: Log and
                    Usage
                    Data. Log and usage data is service-related, diagnostic, usage, and performance information our
                    servers
                    automatically collect when you access or use our Services and which we record in log files.
                    Depending on
                    how you interact with us, this log data may include your IP address, device information, browser
                    type,
                    and settings and information about your activity in the Services (such as the date/time stamps
                    associated with your usage, pages and files viewed, searches, and other actions you take such as
                    which
                    features you use), device event information (such as system activity, error reports (sometimes
                    called
                    "crash dumps"), and hardware settings).
                </Typography>
                <Typography variant="h6">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </Typography>
                <Typography variant="body1">
                    In Short: We process your information to provide, improve, and administer our Services, communicate
                    with you, for security and fraud prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process your personal information for a variety
                    of reasons, depending on how you interact with
                    our<br/>
                    Services, including:<br/>
                    To facilitate account creation and authentication and otherwise manage user
                    accounts. We may process your information so you can create and log in to your account, as well as
                    keep your account in working order. To deliver and facilitate delivery of services to the user. We
                    may process your information to provide you with the requested service. To respond to user
                    inquiries/offer support to users. We may process your information to respond to your inquiries and
                    solve any potential issues you might have with the requested service. To enable user-to-user
                    communications. We may process your information if you choose to use any of our offerings that allow
                    for communication with another user. To request feedback. We may process your information when
                    necessary to request feedback and to contact you about your use of our Services. To deliver targeted
                    advertising to you. We may process your information to develop and display personalized content and
                    advertising tailored to your interests, location, and more. To identify usage trends. We may process
                    information about how you use our Services to better understand how they are being used so we can
                    improve them. To save or protect an individual's vital interest. We may process your information
                    when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                </Typography>

                <Typography variant="h6">
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </Typography>
                <Typography variant="body1">
                    In Short: We only process your personal information when we believe it is necessary and we have
                    a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent,
                    to comply with laws, to provide you with services to enter into or fulfill our contractual
                    obligations, to protect your rights, or to fulfill our legitimate business interests. <br/>

                    The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                    bases we rely on in order to process your personal information. As such, we may rely on the
                    following legal bases to process your personal information:<br/>

                    Consent. We may process your information if you have given us permission (i.e., consent) to use
                    your personal information for a specific purpose. You can withdraw your consent at any time.
                    Learn more about withdrawing your consent.<br/>

                    Performance of a Contract. We may process your personal information when we believe it is
                    necessary to fulfill our contractual obligations to you, including providing our Services or at
                    your request prior to entering into a contract with you.<br/>

                    Legitimate Interests. We may process your information when we believe it is reasonably necessary
                    to achieve our legitimate business interests and those interests do not outweigh your interests
                    and fundamental rights and freedoms. For example, we may process your personal information for
                    some of the purposes described in order to: Develop and display personalized and relevant
                    advertising content for our users Analyze how our Services are used so we can improve them to
                    engage and retain users Understand how our users use our products and services so we can improve
                    user experience.<br/>

                    Legal Obligations. We may process your information where we believe it is necessary for
                    compliance with our legal obligations, such as to cooperate with a law enforcement body or
                    regulatory agency, exercise or defend our legal rights, or disclose your information as evidence
                    in litigation in which we are involved.<br/>

                    Vital Interests. We may process your information where we believe it is necessary to protect
                    your vital interests or the vital interests of a third party, such as situations involving
                    potential threats to the safety of any person. In legal terms, we are generally the "data
                    controller" under European data protection laws of the personal information described in this
                    privacy notice, since we determine the means and/or purposes of the data processing we
                    perform.<br/>

                    This privacy notice does not apply to the personal information we process as a "data processor"
                    on behalf of our customers. In those situations, the customer that we provide services to and
                    with whom we have entered into a data processing agreement is the "data controller" responsible
                    for your personal information, and we merely process your information on their behalf in
                    accordance with your instructions. If you want to know more about our customers' privacy
                    practices, you should read their privacy policies and direct any questions you have to
                    them. <br/>
                </Typography>

                <Typography variant="h6">
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </Typography>
                <Typography variant="body1">
                    In Short: We may share information in specific situations described in this section and/or with
                    the following third parties. Vendors, Consultants, and Other Third-Party Service
                    Providers. <br/>

                    We may share your data with third-party vendors, service providers, contractors, or agents
                    ("third parties") who perform services for us or on our behalf and require access to such
                    information to do that work. We have contracts in place with our third parties, which are
                    designed to help safeguard your personal information. This means that they cannot do anything
                    with your personal information unless we have instructed them to do it. They will also not share
                    your personal information with any organization apart from us. They also commit to protect the
                    data they hold on our behalf and to retain it for the period we instruct. The third parties we
                    may share personal information with are as follows: <br/>

                    Cloud Computing Services: Google Cloud Platform <br/>
                    Content Optimization: Google Site Search and Google Fonts <br/>
                    Website Hosting: Squarespace <br/>
                    Database: MongoDB and Digital Ocean <br/>

                    We also may need to share your personal information in the following situations: <br/>

                    Business Transfers. We may share or transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                    portion of our business to another company. <br/>

                    When we use Google Analytics. We may share your information with Google Analytics to track and
                    analyze the use of the Services. The Google Analytics Advertising Features that we may use
                    include: Google Analytics Demographics and Interests Reporting. To opt out of being tracked by
                    Google Analytics across the Services, visit https://tools.google.com/dlpage/gaoptout. You can
                    opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile
                    apps. Other opt out means include http://optout.networkadvertising.org/ and
                    http://www.networkadvertising.org/mobile-choice. For more information on the privacy practices
                    of Google, please visit the Google Privacy & Terms page. <br/>

                    When we use Google Maps Platform APIs. We may share your information with certain Google Maps
                    Platform APIs (e.g., Google Maps API, Places API).<br/>

                    Other Users. When you share personal information (for example, by posting comments,
                    contributions, or other content to the Services) or otherwise interact with public areas of the
                    Services, such personal information may be viewed by all users and may be publicly made
                    available outside the Services in perpetuity. If you interact with other users of our Services
                    and register for our Services through a social network (such as Facebook), your contacts on the
                    social network will see your name, profile photo, and descriptions of your activity. Similarly,
                    other users will be able to view descriptions of your activity, communicate with you within our
                    Services, and view your profile.
                </Typography>

                <Typography variant="h6">
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </Typography>
                <Typography variant="body1">
                    In Short: We may use cookies and other tracking technologies to collect and store your
                    information. We may use cookies and similar tracking technologies (like web beacons and pixels)
                    to access or store information. Specific information about how we use such technologies and how
                    you can refuse certain cookies is set out in our Cookie Notice.<br/>
                </Typography>

                <Typography variant="h6">
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </Typography>
                <Typography variant="body1">
                    In Short: If you choose to register or log in to our Services using a social media account, we
                    may have access to certain information about you. Our Services offer you the ability to register
                    and log in using your third-party social media account details (like your Facebook or Twitter
                    logins). Where you choose to do this, we will receive certain profile information about you from
                    your social media provider. The profile information we receive may vary depending on the social
                    media provider concerned, but will often include your name, email address, friends list, and
                    profile picture, as well as other information you choose to make public on such a social media
                    platform. We will use the information we receive only for the purposes that are described in
                    this privacy notice or that are otherwise made clear to you on the relevant Services. Please
                    note that we do not control, and are not responsible for, other uses of your personal
                    information by your third-party social media provider. We recommend that you review their
                    privacy notice to understand how they collect, use, and share your personal information, and how
                    you can set your privacy preferences on their sites and apps.<br/>
                </Typography>

                <Typography variant="h6">
                    7. HOW LONG DO WE KEEP YOUR INFORMATION?
                </Typography>
                <Typography variant="body1">
                    In Short: We keep your information for as long as necessary to fulfill the purposes outlined in
                    this privacy notice unless otherwise required by law. We will only keep your personal
                    information for as long as it is necessary for the purposes set out in this privacy notice,
                    unless a longer retention period is required or permitted by law (such as tax, accounting, or
                    other legal requirements). No purpose in this notice will require us keeping your personal
                    information for longer than the period of time in which users have an account with us. When we
                    have no ongoing legitimate business need to process your personal information, we will either
                    delete or anonymize such information, or, if this is not possible (for example, because your
                    personal information has been stored in backup archives), then we will securely store your
                    personal information and isolate it from any further processing until deletion is possible.<br/>
                </Typography>

                <Typography variant="h6">
                    8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </Typography>
                <Typography variant="body1">
                    In Short: We aim to protect your personal information through a system of organizational and
                    technical security measures. We have implemented appropriate and reasonable technical and
                    organizational security measures designed to protect the security of any personal information we
                    process. However, despite our safeguards and efforts to secure your information, no electronic
                    transmission over the Internet or information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
                    third parties will not be able to defeat our security and improperly collect, access, steal, or
                    modify your information. Although we will do our best to protect your personal information,
                    transmission of personal information to and from our Services is at your own risk. You should
                    only access the Services within a secure environment.<br/>
                </Typography>

                <Typography variant="h6">
                    9. DO WE COLLECT INFORMATION FROM MINORS?
                </Typography>
                <Typography variant="body1">
                    In Short: We do not knowingly collect data from or market to children under 18 years of age. We
                    do not knowingly solicit data from or market to children under 18 years of age. By using the
                    Services, you represent that you are at least 18 or that you are the parent or guardian of such
                    a minor and consent to such minor dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been collected, we will deactivate the
                    account and take reasonable measures to promptly delete such data from our records. If you
                    become aware of any data we may have collected from children under age 18, please contact us at
                    peterjknirsch@gmail.com.<br/>
                </Typography>

                <Typography variant="h6">
                    10. WHAT ARE YOUR PRIVACY RIGHTS?
                </Typography>
                <Typography variant="body1">
                    In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and
                    Switzerland, you have rights that allow you greater access to and control over your personal
                    information. You may review, change, or terminate your account at any time. In some regions (like
                    the EEA, UK, and Switzerland), you have certain rights under applicable data protection laws. These
                    may include the right (i) to request access and obtain a copy of your personal information, (ii) to
                    request rectification or erasure; (iii) to restrict the processing of your personal information;
                    (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In
                    certain circumstances, you may also have the right to object to the processing of your personal
                    information. You can make such a request by contacting us by using the contact details provided in
                    the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. We will consider and act upon any
                    request in accordance with applicable data protection laws. If you are located in the EEA or UK and
                    you believe we are unlawfully processing your personal information, you also have the right to
                    complain to your Member State data protection authority or UK data protection authority. If you are
                    located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
                    Withdrawing your consent: If we are relying on your consent to process your personal information,
                    you have the right to withdraw your consent at any time. You can withdraw your consent at any time
                    by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT
                    THIS NOTICE?" below. However, please note that this will not affect the lawfulness of the processing
                    before its withdrawal nor, will it affect the processing of your personal information conducted in
                    reliance on lawful processing grounds other than consent. Opting out of marketing and promotional
                    communications: You can unsubscribe from our marketing and promotional communications at any time by
                    clicking on the unsubscribe link in the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the
                    SMS & E-Mail messages that we send, or by contacting us using the details provided in the section
                    "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing
                    lists. However, we may still communicate with you — for example, to send you service-related
                    messages that are necessary for the administration and use of your account, to respond to service
                    requests, or for other non-marketing purposes.<br/>

                    Account Information<br/>
                    If you would at any time like to review or change the information in your account or terminate your
                    account, you can: Contact us using the contact information provided. Contact us using e-mail
                    (peterjknirsch@gmail.com) or call us +4915756107652 Upon your request to terminate your account, we
                    will deactivate or delete your account and information from our active databases. However, we may
                    retain some information in our files to prevent fraud, troubleshoot problems, assist with any
                    investigations, enforce our legal terms and/or comply with applicable legal requirements. Cookies
                    and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you
                    can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
                    remove cookies or reject cookies, this could affect certain features or services of our Services. If
                    you have questions or comments about your privacy rights, you may email us at
                    peterjknirsch@gmail.com.<br/>
                </Typography>

                <Typography variant="h6">
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES
                </Typography>
                <Typography variant="body1">
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                    your online browsing activities monitored and collected. At this stage no uniform technology
                    standard for recognizing and implementing DNT signals has been finalized. As such, we do not
                    currently respond to DNT browser signals or any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online tracking is adopted that we must follow in
                    the future, we will inform you about that practice in a revised version of this privacy notice.<br/>
                </Typography>

                <Typography variant="h6">
                    12. DO WE MAKE UPDATES TO THIS NOTICE?
                </Typography>
                <Typography variant="body1">
                    In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may
                    update this privacy notice from time to time. The updated version will be indicated by an updated
                    "Revised" date and the updated version will be effective as soon as it is accessible. If we make
                    material changes to this privacy notice, we may notify you either by prominently posting a notice of
                    such changes or by directly sending you a notification. We encourage you to review this privacy
                    notice frequently to be informed of how we are protecting your information.<br/>
                </Typography>

                <Typography variant="h6">
                    13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </Typography>
                <Typography variant="body1">
                    If you have questions or comments about this notice, you may email us at peterjknirsch@gmail.com or
                    contact us by post at: Peter Knirsch Einzelunternehmen Freienwalder Str. 8 Berlin, Berlin 13359
                    Germany If you are a resident in the European Economic Area, we are the "data controller" of your
                    personal information. We have appointed Peter Josef Knirsch to be our representative in the EEA. You
                    can contact them directly regarding our processing of your information, by email at
                    peterjknirsch@gmail.com, by visiting monasteries.de, by phone at +4915756107652, or by post to:
                    Freienwalder Str. 8 Berlin, Berlin 13359 Germany <br/>
                </Typography>

                <Typography variant="h6">
                    14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </Typography>
                <Typography variant="body1">
                    You have the right to request access to the personal information we collect from you, change that
                    information, or delete it. To request to review, update, or delete your personal information, please
                    fill out and submit a data subject access request. <br/>

                    This privacy policy was created using Termly's Privacy Policy Generator <br/>
                </Typography>
            </Box>
        </div>
    )
}

export default PrivacyPolicyPage;