import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";

import Navbar from "../../../components/navbar/Navbar";
import "./index.css"
import DetailsItem from "./components/DetailsItem";
import {useGetFacilityByIdQuery} from "../../../store/emptySplitApi";


const SingleFacilityPage = (props) => {
    const {id} = useParams();
    const {data: facilityData, isFetching, refetch, isUninitialized} = useGetFacilityByIdQuery(id, {skip: !id});

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    return (
        <>
            <Navbar title="Facility Info Page"/>
            <div className="content-container">
                <div className="content-split">
                    {isFetching ? (
                        <Box className="loading-bar">
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <div className="content-left">
                            <h1 className="facility-title">{facilityData.title}</h1>
                            <div className="facility-details">
                                <DetailsItem header="Address" info={facilityData.address}/>
                                <DetailsItem header="Description" info={facilityData.description}/>
                                <DetailsItem header="Telephone" info={facilityData.telephone} isTelephone={true}/>
                                <DetailsItem header="Email" info={facilityData.email} isEmail={true}/>
                                <DetailsItem header="Website"
                                             info={facilityData.website ? facilityData.website : "None"}
                                             isLink={facilityData.website}/>
                                <DetailsItem header="Facility Leader" info={facilityData.facilityLeader?.fullname}/>
                                <DetailsItem header="Facility Type" info={facilityData.facilityTypes} isArray={true}/>
                                <DetailsItem header="Donation Based" info={facilityData.donationBased ? "Yes" : "No"}/>
                                <DetailsItem header="Bookable for External Retreat Leaders" info={facilityData.bookable ? "Yes" : "No"}/>
                                <DetailsItem header="Cost For Housing" info={facilityData.costForHousing ? facilityData.costForHousing + "€" : "Unknown"}/>
                                <DetailsItem header="Genders" info={facilityData.genders} isArray={true}/>
                                <DetailsItem header="Denominations" info={facilityData.denominations} isArray={true}/>
                            </div>
                        </div>
                    )}
                    {/*<div className="content-right">*/}
                    {/*    <div className="facility-image">*/}
                    {/*        <img className="facility-image__img" src={facility.image} alt="Facility"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*<div className="all-events-container">*/}
                {/*    <h3 className="events-title">Events</h3>*/}
                {/*    <EventsList eventValues={eventValues} search={""} pageSize={5} pageLink={location.pathname}/>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default SingleFacilityPage;