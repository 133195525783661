import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CircularProgress, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";

import Navbar from "../../../components/navbar/Navbar";
import EventsList from "../../../components/EventsList";
import {iconStyle} from "../AllEventsPage";
import MultipleSelectMenu from "../../../components/MultipleSelectMenu";
import NumberInput from "../../../components/NumberInput";
import {
    useGetAllGendersQuery,
    useGetAllLanguagesQuery,
    useGetAllRetreatTypesQuery,
    useGetMyEventsQuery
} from "../../../store/emptySplitApi";

export default function MyEventsPage() {
    const navigate = useNavigate();

    const userId = useSelector(state => state.auth.user.user.id);

    const {data: eventsData, isFetching, refetch, isUninitialized} = useGetMyEventsQuery(userId, {
        skip: !userId,
    })

    const {data: genders = []} = useGetAllGendersQuery()
    const {data: languages = []} = useGetAllLanguagesQuery()
    const {data: retreatTypes = []} = useGetAllRetreatTypesQuery()

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedRetreatTypes, setSelectedRetreatTypes] = useState([]);
    const [minCost, setMinCost] = useState(0);
    const [maxCost, setMaxCost] = useState(0);

    const [search, setSearch] = useState('');

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    return (
        <>
            <Navbar title="Events" child={
                <Box
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                    }}
                >
                    <TextField
                        label="Search events"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <Search sx={iconStyle}/>
                            )
                        }}
                    />
                    {userId && (
                        <div className="facility-details">
                            <Button variant="outlined" onClick={() => navigate('/event/newEvent')}>Create
                                Event</Button>
                        </div>
                    )}
                </Box>
            }/>
            <div className="content-container">
                <div className="filter-bar">
                    <MultipleSelectMenu label={"Languages"} values={languages} selectedValues={selectedLanguages}
                                        setSelectedValues={setSelectedLanguages}/>
                    <MultipleSelectMenu label={"Genders"} values={genders} selectedValues={selectedGenders}
                                        setSelectedValues={setSelectedGenders}/>
                    <MultipleSelectMenu label={"Retreat Types"} values={retreatTypes}
                                        selectedValues={selectedRetreatTypes}
                                        setSelectedValues={setSelectedRetreatTypes}/>
                    <NumberInput label={"Min Cost, €"} value={minCost} setValue={setMinCost}/>
                    <NumberInput label={"Max Cost, €"} value={maxCost} setValue={setMaxCost}/>
                </div>
                {isFetching ? (
                    <Box className="loading-bar">
                        <CircularProgress/>
                    </Box>
                    ) :
                    eventsData.message && eventsData.message === "NO_FACILITY_FOUND" ? (
                        <div className="content-left">
                            <h1 className="facility-title">To create and see the events you need to create facility
                                first!</h1>
                            <div className="facility-details">
                                <Button variant="outlined" onClick={() => navigate('/facility/newFacility')}>Create
                                    facility</Button>
                            </div>
                        </div>
                    ) : (
                        <EventsList
                            canEdit={!!userId}
                            canDelete={!!userId}
                            refetch={refetch}
                            eventValues={eventsData}
                            search={search}
                        />
                    )
                }

            </div>
        </>
    );
}
