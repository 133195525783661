import React from "react";
import "../index.css";
import {Link} from "react-router-dom";


function ensureHttpPrefix(url) {
    // Check if url starts with 'http://' or 'https://'
    if (/^https?:\/\//i.test(url)) {
        return url; // URL is fine, return as is
    } else {
        return `https://${url}`; // Prepend 'https://' to URL
    }
}

const renderContent = ({info, isArray, isLink, facilityLink, isEmail, isTelephone}) => {
    if (isArray) {
        if (!info?.length) return "Any";
        return (
            <ul className="facility-details__item__info array">
                {info?.map((item, index) => (
                    // Consider using a unique identifier from item if possible
                    <li key={item._id}>{item.name}</li>
                ))}
            </ul>
        );
    } else if (isLink) {
        return <a
            href={ensureHttpPrefix(info)}
            className="facility-details__item__info"
            target="_blank"
            rel="noopener noreferrer"
        >
            {info}
        </a>;
    } else if (facilityLink) {
        return <Link to={`/facility/${facilityLink}`} className="facility-details__item__info">{info}</Link>;
    } else if (isEmail || isTelephone) {
        const href = isEmail ? `mailto:${info}` : `tel:${info}`;
        return <a href={href} className="facility-details__item__info">{info}</a>;
    } else {
        return <p className="facility-details__item__info">{info}</p>;
    }
};

const DetailsItem = ({
                         header,
                         info,
                         isArray = false,
                         isLink = false,
                         facilityLink = "",
                         isEmail = false,
                         isTelephone = false
                     }) => (
    <div className="facility-details__item">
        <h4 className="facility-details__item__title">{header}</h4>
        {renderContent({info, isArray, isLink, facilityLink, isEmail, isTelephone})}
    </div>
);

export default React.memo(DetailsItem);
