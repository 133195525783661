import {Box, Pagination, PaginationItem, Stack} from "@mui/material";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {CalendarMonth, Euro, Language, Person, Spa, Transgender} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {iconStyle} from "../../scenes/Event/AllEventsPage";
import {useDeleteEventMutation} from "../../store/emptySplitApi";
import Button from "@mui/material/Button";

const EventsList = ({
                        canEdit,
                        canDelete,
                        deleteEventFunc,
                        refetch,
                        eventValues = [],
                        search,
                        pageSize = 10,
                    }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(
        parseInt(location.pathname.split("=")[1] || 1)
    );
    const [pageQty, setPageQty] = useState(0);

    useEffect(() => {
        const searchEvents = eventValues.filter(event => event.title.toLowerCase().includes(search.toLowerCase()));
        setEvents(prev => searchEvents.slice((page - 1) * pageSize, page * pageSize));
        const numberOfPages = Math.ceil(searchEvents.length / pageSize);
        setPageQty(numberOfPages);

        if (numberOfPages < page) {
            setPage(1);
            navigate(location.pathname, {replace: true});
        }
    }, [search, page]);

    const [deleteEvent, {isLoading}] = useDeleteEventMutation();

    const deleteEventById = (eventId) => {
        if (!canDelete) return () => console.log('No permision');

        return async () => {
            try {
                const deleteEventData = await deleteEvent(eventId);

                if (deleteEventData.error) {
                    console.log(`error: ${JSON.stringify(deleteEventData.error)}`)
                } else {
                    console.log('deletion of the event is successful')
                    refetch();
                }
            } catch (error) {
                console.log(`error: ${JSON.stringify(error)}`)
            }
        };
    }

    return (
        <div className="events-container">
            <Stack spacing={2}>
                {!!pageQty && (
                    <Pagination
                        count={pageQty}
                        page={page}
                        onChange={(_, num) => setPage(num)}
                        showFirstButton
                        showLastButton
                        sx={{marginY: 3, marginX: "auto"}}
                        renderItem={(item) => (
                            <PaginationItem
                                component={NavLink}
                                to={`${location.pathname}?page=${item.page}`}
                                {...item}
                            />
                        )}
                    />
                )}
                {events.map((event, index) => {
                    return (
                        <Box className="event-item" key={index}>
                            <div>
                                <Link to={`/event/${event._id}`} className="event-head-link">
                                    <h3 className="event-title">{event.title}</h3>
                                    <p className="event-description">{event.description}</p>
                                </Link>
                            </div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: "flex-end",
                                    gap: '1rem',
                                    width: '55%',
                                }}
                            >
                                <Box className="event-details">
                                    <Box className="event-details-item">
                                        <Person sx={iconStyle}/>
                                        <p>{event.facilitator.title}</p>
                                    </Box>
                                    <Box className="event-details-item">
                                        <Transgender sx={iconStyle}/>
                                        {event.genders && event.genders.length !== 0 ? event.genders.map((gender) => gender.name).join(', ') : "Any"}
                                    </Box>
                                    <Box className="event-details-item">
                                        <Euro sx={iconStyle}/>
                                        <p>{event.cost ? event.cost : "Unknown"}</p>
                                    </Box>
                                    <Box className="event-details-item">
                                        <CalendarMonth sx={iconStyle}/>
                                        <p>{new Date(event.startDate).toLocaleDateString()}</p>
                                    </Box>
                                    <Box className="event-details-item">
                                        <Spa sx={iconStyle}/>
                                        {event.retreatTypes && event.retreatTypes.length !== 0 ? event.retreatTypes.map((retreatType) => retreatType.name).join(', ') : "Any"}
                                    </Box>
                                    <Box className="event-details-item">
                                        <Language sx={iconStyle}/>
                                        {event.languages && event.languages.length !== 0 ? event.languages.map((language) => language.name).join(', ') : "Any"}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '1rem',
                                    }}
                                >
                                    {canEdit && (
                                        <Button variant="outlined" sx={{width: "10rem"}}
                                                onClick={() => navigate(`/event/editEvent/${event._id}`)}>Edit</Button>
                                    )}
                                    {canDelete && (
                                        <Button variant="outlined" sx={{width: "10rem"}}
                                                onClick={deleteEventById(event._id)}>Delete</Button>
                                    )}
                                </Box>

                            </Box>
                        </Box>
                    )
                })}
            </Stack>
        </div>
    );
}

export default EventsList;