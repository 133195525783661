import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Box, Button, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {useDeleteFacilityMutation, useGetMyFacilityQuery} from "../../../store/emptySplitApi";
import Navbar from "../../../components/navbar/Navbar";
import DetailsItem from "../SingleFacilityPage/components/DetailsItem";
import './index.css';

const FacilityComponent = ({facility, canEdit, canDelete, deleteFacilityFunc}) => {
    const navigate = useNavigate();

    return (
        <>
            {
                facility ? (
                    <div className="content-left">
                        <h1 className="facility-title">{facility.title}</h1>
                        <div className="facility-details">
                            <DetailsItem header="Address" info={facility.address}/>
                            <DetailsItem header="Description" info={facility.description}/>
                            <DetailsItem header="Telephone" info={facility.telephone} isTelephone={true}/>
                            <DetailsItem header="Email" info={facility.email} isEmail={true}/>
                            <DetailsItem header="Website" info={facility.website ? facility.website : "None"}
                                         isLink={facility.website}/>
                            <DetailsItem header="Facility Leader" info={facility.facilityLeader?.fullname}/>
                            <DetailsItem header="Facility Type" info={facility.facilityTypes} isArray={true}/>
                            <DetailsItem header="Donation Based" info={facility.donationBased ? "Yes" : "No"}/>
                            <DetailsItem header="Bookable for External Retreat Leaders" info={facility.bookable ? "Yes" : "No"}/>
                            <DetailsItem header="Genders" info={facility.genders} isArray={true}/>
                            <DetailsItem header="Denominations" info={facility.denominations} isArray={true}/>
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                            }}
                        >
                            {canEdit && (
                                <Button variant="outlined"
                                        onClick={() => navigate(`/facility/editFacility/${facility._id}`)}>Edit</Button>
                            )}
                            {canDelete && (
                                <Button variant="outlined"
                                        onClick={deleteFacilityFunc}>Delete</Button>
                            )}
                        </Box>
                    </div>
                ) : (
                    <div className="content-left">
                        <h1 className="facility-title">No facility created</h1>
                        <div className="facility-details">
                            <Button variant="outlined" onClick={() => navigate('/facility/newFacility')}>Create facility</Button>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default function MyFacilityPage() {
    const navigate = useNavigate();
    const userId = useSelector(state => state.auth.user.user.id);

    const {data: facility = [], isFetching, refetch, isUninitialized} = useGetMyFacilityQuery(userId, {
        skip: !userId,
    });
    const [deleteFacility, {isLoading}] = useDeleteFacilityMutation();

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    const deleteFacilityById = (facilityId) => {
        return async () => {
            try {
                const deleteFacilityData = await deleteFacility(facilityId);

                if (deleteFacilityData.error) {
                    console.log(`error: ${JSON.stringify(deleteFacilityData.error)}`)
                } else {
                    console.log('deletion of the facility is successful')
                    refetch();
                }
            } catch (error) {
                console.log(`error: ${JSON.stringify(error)}`)
            }
        };
    }

    return (
        <>
            <Navbar title="Facility Info Page"
                    child={userId && (
                        <div className="facility-details">
                            <Button variant="outlined" onClick={() => navigate('/facility/newFacility')}>Create
                                Facility</Button>
                        </div>
                    )}
            />
            <div className="content-container">
                <div className="content-split">
                    {isFetching ? (
                            <Box className="loading-bar">
                                <CircularProgress/>
                            </Box>
                        ) :
                        facility instanceof Array && facility.length > 1 ?
                            (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '1rem',
                                    }}
                                >
                                    {facility.map(facility => <FacilityComponent canEdit={!!userId}
                                                                                 canDelete={!!userId}
                                                                                 deleteFacilityFunc={deleteFacilityById(facility._id)}
                                                                                 key={facility._id}
                                                                                 facility={facility}
                                    />)}
                                </Box>
                            )
                            :
                            <FacilityComponent
                                canEdit={!!userId}
                                canDelete={!!userId}
                                deleteFacilityFunc={facility.length === 0 ? undefined : deleteFacilityById(facility[0]._id)}
                                facility={facility.length === 0 ? undefined : facility[0]}
                            />
                    }
                </div>
            </div>
        </>
    )
}
