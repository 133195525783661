import React from 'react';
import {Link, NavLink} from "react-router-dom";
import './Header.css';
import {ReactComponent as LogoSvg} from '../../icons/logo-icon.svg';
import {Person} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from '@mui/base/Dropdown';
import {Menu} from '@mui/base/Menu';
import {styled} from '@mui/system';
import {MenuButton as BaseMenuButton} from '@mui/base/MenuButton';
import {MenuItem as BaseMenuItem, menuItemClasses} from '@mui/base/MenuItem';
import {logout as authLogout} from "../../store/authSlice/authSlice";
import {persistor} from "../../store/index.tsx";
import {useLogoutMutation} from "../../store/emptySplitApi";

const linkStyle = ({isActive}) => isActive ? "header-link__item active" : "header-link__item";

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Listbox = styled('ul')(
    ({theme}) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
        theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
    };
  z-index: 1;
  `,
);

const MenuItem = styled(BaseMenuItem)(
    ({theme}) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }
  `,
);

const MenuButton = styled(BaseMenuButton)(
    ({theme}) => `
  transition: all 150ms ease;
  cursor: pointer;
  background-color: transparent;
  border: none;
  `,
);

function Header(props) {
    const dispatch = useDispatch();
    const [logout, {isLoading}] = useLogoutMutation();

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const accessToken = useSelector(state => state.auth?.user?.accessToken);
    const userId = useSelector(state => state.auth?.user?.user?.id);
    const onLogout = async () => {
        try {
            const logoutData = await logout({accessToken, userId}).unwrap();

            dispatch(authLogout());
            persistor.purge()
                .then(() => {
                    console.log('Logged out');
                })
                .catch(error => {
                    console.error('Persistor purge error:', error);
                });
        } catch (error) {
            console.log(`error`)
            console.log(error);
        }
    };

    return (
        <div className="navbar-container">
            <div className="left">
                <Link to="/" className="logo-link">
                    <div className="logo-container">
                        <LogoSvg style={{height: "50px", width: "50px"}}/>
                        <h1 className="logo-title">ātum</h1>
                    </div>
                </Link>
                <NavLink to="/" className={linkStyle}>
                    Facilities Map
                </NavLink>
                <NavLink to="/allEvents" className={linkStyle}>
                    Events
                </NavLink>
            </div>
            {isAuthenticated ?
                <div className="right">
                    <NavLink to="/myFacility" className={linkStyle}>
                        My Facility
                    </NavLink>
                    <NavLink to="/myEvents" className={linkStyle}>
                        My Events
                    </NavLink>
                    <Dropdown>
                        <MenuButton onClick={() => console.log('profile icon is clicked')}>
                                <Person sx={{fontSize: "25px", color: "var(--color-header-text)"}}/>
                        </MenuButton>
                        <Menu slots={{listbox: Listbox}}>
                            <MenuItem onClick={onLogout}>Log out</MenuItem>
                        </Menu>
                    </Dropdown>

                </div>
                :
                <div className="right">
                    <NavLink to="/login" className={linkStyle}>
                        Login
                    </NavLink>
                    <NavLink to="/signup" className={linkStyle}>
                        Sign Up
                    </NavLink>
                </div>}
        </div>
    );
}

export default Header;