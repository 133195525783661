import {Box, FormControl, OutlinedInput, Typography} from "@mui/material";
import React from "react";

const StyledInputWithoutForm = ({label, errors, errorMessage, ...props}) => {
    return (
        <FormControl
            display='flex'
            flexDirection="column"
            sx={{
                width: "45%",
                margin: "1rem",
            }}
        >
            <Typography
                sx={{
                    ml: "0.3rem",
                    mb: "0.3rem",
                    fontSize: "1.2rem",
                }}
            >
                {label}
            </Typography>
            <OutlinedInput
                placeholder={label}
                sx={{
                    fontSize: "1rem"
                }}
                {...props}
            />
            {errorMessage}
            {errors && (
                <Box
                    color="#ED0131"
                    fontSize="16px"
                >
                    {errors.message}
                </Box>
            )}
        </FormControl>
    )
}

export default StyledInputWithoutForm;