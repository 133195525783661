import {Box, Button, CircularProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FilterAlt} from "@mui/icons-material";
import "leaflet/dist/leaflet.css";

import Navbar from "../../../components/navbar/Navbar";
import MapView from "../../../components/MapView/MapView";
import '../../../styles.css';
import "./index.css"
import MultipleSelectMenu from "../../../components/MultipleSelectMenu";
import NumberInput from "../../../components/NumberInput";
import SelectMenu from "../../../components/SelectMenu";
import {
    useGetAllDenominationsQuery,
    useGetAllFacilitiesQuery,
    useGetAllFacilityTypesQuery,
    useGetAllGendersQuery
} from "../../../store/emptySplitApi";

export default function AllFacilitiesMapPage() {
    const [filters, setFilters] = useState({});

    const {data: facilitiesData, refetch, isFetching, isUninitialized} = useGetAllFacilitiesQuery(filters, {
        refetchOnReconnect: true,
    });

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    const {data: denominations = []} = useGetAllDenominationsQuery()
    const {data: genders = []} = useGetAllGendersQuery()
    const {data: facilityTypes = []} = useGetAllFacilityTypesQuery()

    const [selectedDenominations, setSelectedDenominations] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedFacilityTypes, setSelectedFacilityTypes] = useState([]);
    const [minCostForHousing, setMinCostForHousing] = useState(0);
    const [maxCostForHousing, setMaxCostForHousing] = useState(0);
    const [donationBased, setDonationBased] = useState("");
    const [bookable, setBookable] = useState("");

    const handleFiltersSubmit = (e) => {
        const filters = {
            denominations: typeof selectedDenominations === 'string' ? [selectedDenominations] : selectedDenominations.join(','),
            genders: typeof selectedGenders === 'string' ? [selectedGenders] : selectedGenders.join(','),
            facilityTypes: typeof selectedFacilityTypes === 'string' ? [selectedFacilityTypes] : selectedFacilityTypes.join(','),
            minCostForHousing: minCostForHousing.toString(),
            maxCostForHousing: maxCostForHousing.toString(),
            donationBased: donationBased, // Assuming this state is already a string "true" or "false" or ""
            bookable: bookable // Assuming this state is already a string "true" or "false" or ""
        };

        Object.keys(filters).forEach(key => {
            if (!filters[key] || filters[key] === '0' || filters[key] === "") {
                delete filters[key];
            }
        });

        setFilters(prev => filters);
    }

    return (
        <div className="map-main-container">
            <Navbar title="Facilities Map"/>
            <div className="content-container">
                <div className="filter-bar">
                    <MultipleSelectMenu label={"Denomination"} values={denominations}
                                        selectedValues={selectedDenominations}
                                        setSelectedValues={setSelectedDenominations}/>
                    <MultipleSelectMenu label={"Genders"} values={genders} selectedValues={selectedGenders}
                                        setSelectedValues={setSelectedGenders}/>
                    <MultipleSelectMenu label={"Facility Types"} values={facilityTypes}
                                        selectedValues={selectedFacilityTypes}
                                        setSelectedValues={setSelectedFacilityTypes}/>
                    <NumberInput label={"Min Housing Cost, €"} value={minCostForHousing} setValue={setMinCostForHousing}/>
                    <NumberInput label={"Max Housing Cost, €"} value={maxCostForHousing} setValue={setMaxCostForHousing}/>
                    <SelectMenu label={"Donation Based"}
                                values={[{name: "Yes", value: true}, {name: "No", value: false}]}
                                selectedValue={donationBased} setSelectedValue={setDonationBased}/>
                    <SelectMenu label={"Bookable for External Retreat Leaders"} values={[{name: "Yes", value: true}, {name: "No", value: false}]}
                                selectedValue={bookable} setSelectedValue={setBookable}/>
                    <Box
                        sx={{
                            m: 1,
                            // maxWidth: "22%",
                            width: "25rem",
                        }}
                    >
                        <Button variant="contained" startIcon={<FilterAlt/>}
                                onClick={handleFiltersSubmit}
                                sx={{
                                    backgroundColor: "#BA9568",
                                    padding: "0.6rem",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    "&:hover": {backgroundColor: "#836136",},
                                }}
                        >
                            Apply Filters
                        </Button>
                    </Box>
                </div>
                {isFetching ? (
                    <Box className="loading-bar">
                        <CircularProgress/>
                    </Box>
                ) : (
                    <div className="map-container">
                        <MapView facilitiesData={facilitiesData}/>
                    </div>
                )}
            </div>
        </div>
    );
}