import Navbar from "../../../components/navbar/Navbar";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const AboutPage = (props) => {
    return (
        <div className="map-main-container">
            <Navbar title="About Creator"/>
            <Box
                className="content-container"
                sx={{
                    maxWidth:"60%",
                    "& .MuiTypography-root": {
                        mt: "1rem",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                    }
                }}
            >
                <Typography variant="body1" >
                    Hi, I’m Peter, 23 years old and living in Berlin.
                </Typography>
                <Typography variant="body1" >
                    In 2020, I had my first stay at a Buddhist monastery in Germany. I was overwhelmed by the city, by
                    the constant stream of messages on my phone, by the noise of my environment. I needed some time for
                    myself and a monastery seemed like the perfect place.
                </Typography>
                <Typography variant="body1" >
                    Since then, I’ve been going to monasteries of different denominations at least once per year. My
                    city life is filled with external stimuli, notifications, and duties. Staying at a monastery poses
                    the perfect balance – time for myself, for contemplation, and a place to recharge.
                </Typography>
                <Typography variant="body1" >
                    I’m on a mission to make monasteries ‘cool’ again and I’d love to see our future society reusing
                    these wonderful locations for healing, personal development, contemplation, connecting with oneself,
                    connecting with nature – or whatever words to describe the indescribable.
                </Typography>
                <Typography variant="body1" >
                    ‘ātum’ is the old-high-german word for breath - something that connects us all. I have learned
                    invaluable lessons from all different sorts of religions, traditions, paths, and philosophies. Thus,
                    I want this website to become a platform for all beliefs or non-beliefs and hopefully also a place
                    for interreligious exchange.
                </Typography>
                <Typography variant="body1" >
                    This is the first version of hopefully many on trying to make spiritual locations and events for
                    healing more accessible and mainstream :)
                </Typography>
                <Typography variant="body1" >
                    If you like this (or don’t), I’d love your feedback! Please write an email to
                    peterjknirsch@gmail.com
                </Typography>
                <Typography variant="body1" sx={{mt: "2rem"}}>
                    Alles Liebe (meaning Everything Love),<br/>
                    Peter
                </Typography>
            </Box>
        </div>
    )
}

export default AboutPage;