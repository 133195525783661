import {useLocation, Navigate} from 'react-router-dom'
import {useSelector} from "react-redux";

const RequireAuth = ({children}) => {
    const location = useLocation();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    // todo: add api call to check if the user is authorized to access the page

    if (!isAuthenticated) {
        return <Navigate to='/login' state={{from: location}}/>;
    }
    return children;
}

export default RequireAuth;