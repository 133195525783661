import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useRegisterMutation} from "../../../store/emptySplitApi";
import * as yup from "yup";
import {Formik} from "formik";
import {setUser} from "../../../store/authSlice/authSlice";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="/">
                Ātum
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    // telephone: '',
};

const telephonePatter = /^(\+49|0049)?[1-9](\d{1,3})(\d{3,})$/;

const signUpSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
    // telephone: yup
    //     .string()
    //     .matches(telephonePatter, "Phone number is not valid")
    //     .required("Phone number is required"),
});

export default function SignUpPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [signUp, {isLoading}] = useRegisterMutation();
    const [error, setError] = useState(null);

    const onSubmit = async (signUpFormData) => {
        try {
            const registerData = await signUp(signUpFormData).unwrap();
            if (registerData.error) {
                setError(prev => registerData.error);
            } else {
                dispatch(setUser(registerData));
                navigate('/', {replace: true});
            }
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up as Facility Leader
                    </Typography>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={signUpSchema}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit
                          }) => (
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    autoComplete="given-name"
                                    autoFocus
                                    error={!!touched.firstName && !!errors.firstName}
                                    helperText={touched.firstName && errors.firstName}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    autoComplete="family-name"
                                    error={!!touched.lastName && !!errors.lastName}
                                    helperText={touched.lastName && errors.lastName}
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    autoComplete="email"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="new-password"
                                    error={!!touched.password && !!errors.password}
                                    helperText={touched.password && errors.password}
                                    onChange={handleChange}
                                    value={values.password}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        id="telephone"*/}
                            {/*        name="telephone"*/}
                            {/*        label="Telephone"*/}
                            {/*        error={!!touched.telephone && !!errors.telephone}*/}
                            {/*        helperText={touched.telephone && errors.telephone}*/}
                            {/*        onChange={handleChange}*/}
                            {/*        value={values.telephone}*/}
                            {/*    />*/}
                            {/*</Grid>*/}

                            {error && (
                                <Typography
                                    variant="h4"
                                    color="#db4f4a"
                                    mt="15px"
                                >
                                    {error}
                                </Typography>
                            )}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to="/login">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                        )}
                    </Formik>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}