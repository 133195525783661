import Navbar from "../../../components/navbar/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {Link} from "react-router-dom";

const Impressum = (props) => {
    return (
        <div className="map-main-container">
            <Navbar title="Impressum"/>
            <Box
                className="content-container"
                sx={{
                    maxWidth: "70%",
                    "& .MuiTypography-root": {
                        mt: "1rem",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                    },
                    "& .MuiTypography-h5": {
                        mt: "3rem",
                        fontSize: "1.8rem",
                        fontWeight: "600",
                    },
                    "& .MuiTypography-h6": {
                        mt: "2rem",
                        fontSize: "1.5rem",
                        fontWeight: "600",
                    }
                }}
            >
                <Typography variant="body1">
                    The operator of the platform monasteries.de according to § 5 TMG is:
                </Typography>
                <Typography variant="body1">
                    Peter Josef Knirsch Einzelunternehmen
                    Freienwalder Str. 8
                    13359 Berlin
                </Typography>
                <Typography variant="h6">
                    Contact
                </Typography>
                <Typography variant="body1">
                    Phone number: +4915756107652<br/>
                    E-mail address: peterjknirsch@gmail.com
                </Typography>
                <Typography variant="body1">
                    Responsible for journalistic and editorial content is Peter Josef Knirsch, Freienwalder Str. 8,
                    13359 Berlin<br/>
                    Our Privacy Policy can be found here: <Link
                    to="/privacyPolicy">https://monasteries.de/privacyPolicy</Link>
                </Typography>

                <Typography variant="h5">
                    Disclaimer & Liability
                </Typography>
                <Typography variant="h6">
                    Disclaimer on Health and Mental Health Advice
                </Typography>
                <Typography variant="body1">
                    The information provided on our platform, including but not limited to text, graphics, images, and
                    other material, is for informational purposes only and is not intended as a substitute for
                    professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or
                    other qualified health providers with any questions you may have regarding a medical condition.
                    Never disregard professional medical advice or delay in seeking it because of something you have
                    read on this website.
                </Typography>

                <Typography variant="h6">
                    Disclaimer on Third-Party Content
                </Typography>
                <Typography variant="body1">
                    Our platform is not responsible for any information, data, or content published by users or third
                    parties. The views expressed by users or third parties are their own and do not necessarily reflect
                    the views of our platform. We do not endorse, guarantee, or assume responsibility for the accuracy,
                    efficacy, or veracity of any information provided by third parties.
                </Typography>

                <Typography variant="h6">
                    Copyright Policy
                </Typography>
                <Typography variant="body1">
                    All content provided on this platform, including texts, graphics, logos, images, and educational
                    materials, is the property of our platform or is used with permission. This content is protected by
                    copyright and may not be copied, reproduced, distributed, transmitted, displayed, performed, or
                    otherwise used without the prior written consent of our platform. We encourage the sharing of our
                    content for non-commercial use, provided that the original source is acknowledged and the content
                    remains unaltered.
                </Typography>

                <Typography variant="h6">
                    Disclaimer on Religious Belief
                </Typography>
                <Typography variant="body1">
                    In crafting our content and fostering discussions, we approach all religious beliefs with the utmost
                    respect and sensitivity. Our intention is never to harm, criticize, or diminish the value of any
                    religion or spiritual practice. Instead, we aspire to create a platform that embodies the principles
                    of peace, love, and mutual understanding. Recognizing the deep significance of faith in people's
                    lives, we aim to facilitate a respectful exchange of ideas and beliefs. Our ultimate goal is to
                    contribute to a community that celebrates diversity and promotes an atmosphere of respect, where
                    every belief is approached with an open heart and mind.
                </Typography>

                <Typography variant="h6">
                    Disclaimer on External Links
                </Typography>
                <Typography variant="body1">
                    Our platform may contain links to external websites that are not provided or maintained by or in any
                    way affiliated with us. Please note that we do not guarantee the accuracy, relevance, timeliness, or
                    completeness of any information on these external websites. We are not responsible for the content,
                    privacy policies, or practices of any third-party sites linked to or from our platform.
                </Typography>

                <Typography variant="h6">
                    Ethical & Safety Guidelines
                </Typography>
                <Typography variant="body1">
                    We are committed to maintaining high ethical standards and ensuring the safety of all users of our
                    platform. We promote respect, integrity, and the responsible use of our platform. While we encourage
                    open discussion and the exchange of ideas, we also insist on respectful and considerate
                    interactions. We strictly prohibit the promotion of illegal activities, including but not limited to
                    the unauthorized use of controlled substances. Our community guidelines are designed to support a
                    safe, informative, and inclusive environment for all users.
                </Typography>

            </Box>
        </div>
    )
}

export default Impressum;