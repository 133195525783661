import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Chip} from "@mui/material";
import Box from "@mui/material/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelect({label, values = [], selectedValues, setSelectedValues, isFacilityRender}) {
    return (
        <div>
            <FormControl
                fullWidth={true}
                sx={{
                    m: 1,
                    // maxWidth: "22%",
                    width: "25rem",
                }}
            >
                <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
                <Select
                    autoWidth={true}
                    labelId="multiple-label"
                    id="multiple"
                    multiple
                    value={selectedValues}
                    onChange={(event) => setSelectedValues(event.target.value)}
                    input={<OutlinedInput label={label}/>}
                    renderValue={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map(id => {
                                if (isFacilityRender)
                                    return (<Chip key={id} label={values.find(val => val._id === id).title}/>)
                                return (<Chip key={id} label={values.find(val => val._id === id).name}/>)
                            })}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {values.map((v) => (
                        <MenuItem
                            key={v._id}
                            value={v._id}
                        >
                            {isFacilityRender ? v.title : v.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}