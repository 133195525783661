import React from 'react';
import {Outlet} from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";


function Layout(props) {
    return (
        <div className="main-container">
            <Header/>
            <div className="content-wrapper">
                <Outlet/>
            </div>

            <Footer/>
        </div>
    );
}

export default Layout;