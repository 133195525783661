import React, {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Navbar from "../../../components/navbar/Navbar";
import DetailsItem from "../../Facility/SingleFacilityPage/components/DetailsItem";
import {useGetEventByIdQuery} from "../../../store/emptySplitApi";
import {Box, CircularProgress} from "@mui/material";

function SingleEventPage(props) {
    const {id} = useParams();
    const {data: eventData, isFetching, refetch, isUninitialized} = useGetEventByIdQuery(id, {skip: !id});

    const location = useLocation();

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    return (
        <>
            <Navbar title="Event Page"/>
            <div className="content-container">
                <div className="content-split">
                    {isFetching ? (
                        <Box className="loading-bar">
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <div className="content-left">
                            <h1 className="facility-title">{eventData.title}</h1>
                        <div className="facility-details">
                            {eventData.description && (
                                <DetailsItem header="Description" info={eventData.description}/>)}
                            <DetailsItem header="Facilitator" info={eventData.facilitator?.title}
                                         facilityLink={eventData.facilitator?.id}/>
                            <DetailsItem header="Retreat Type" info={eventData.retreatTypes} isArray={true}/>
                            <DetailsItem header="Start Date" info={new Date(eventData.startDate).toLocaleDateString()}/>
                            <DetailsItem header="End Date" info={new Date(eventData.endDate).toLocaleDateString()}/>
                            <DetailsItem header="Cost" info={eventData.cost + "€"}/>
                            {eventData.costForHousing !== undefined && (
                                <DetailsItem header="Cost for Housing" info={eventData.costForHousing + "€"}/>)}
                            {eventData.link && (<DetailsItem header="Link" info={eventData.link} isLink={true}/>)}
                            <DetailsItem header="Languages" info={eventData.languages} isArray={true}/>
                            <DetailsItem header="Genders" info={eventData.genders} isArray={true}/>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SingleEventPage;