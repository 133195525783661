import React from 'react';
import "./Footer.css";
import {Link} from "react-router-dom";

function Footer(props) {
    return (
        <div className="footer-wrapper">
            <div className="footer-distributed">
                <div className="footer-left">
                    <p>Peter Knirsch &copy; 2024</p>
                </div>
                <div className="footer-links">
                    <Link to="/" className="footer-link">Home</Link>
                    <Link to="/about" className="footer-link">About</Link>
                    <Link to="/impressum" className="footer-link">Impressum</Link>
                    <Link to="/privacyPolicy" className="footer-link">Privacy Policy</Link>
                </div>
            </div>
        </div>

    );
}

export default Footer;