import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Button, CircularProgress, Typography} from "@mui/material";

import Navbar from "../../../components/navbar/Navbar";
import StyledFormBox from "../../../components/StyledFormBox";
import StyledInputWithoutForm from "../../../components/StyledInputWithoutForm";
import MultipleSelectMenu from "../../../components/MultipleSelectMenu";
import SelectMenu from "../../../components/SelectMenu";
import {
    useGetAllDenominationsQuery,
    useGetAllFacilityTypesQuery,
    useGetAllGendersQuery,
    useGetFacilityByIdQuery,
    useUpdateFacilityMutation
} from "../../../store/emptySplitApi";


const errorMessages = {
    title: "Title is required.",
    description: "Description is required.",
    address: "Address is required.",
    telephone: "Telephone is required.",
    email: "Email is required.",
    website: "Website is required.",
    costForHousing: "Cost for housing is required and should be more than 0.",
    denominations: "Denomination is required.",
    genders: "Genders is required.",
    facilityTypes: "Facility Types is required.",
    donationBased: "It's required to set if the facility is donation based",
    bookable: "It's required to set if the facility is bookable for external Retreat Leaders",
}

export default function EditFacilityPage({}) {
    const {data: denominations = []} = useGetAllDenominationsQuery()
    const {data: genders = []} = useGetAllGendersQuery()
    const {data: facilityTypes = []} = useGetAllFacilityTypesQuery()

    const {id: facilityId} = useParams();
    const {data: facilityData, isFetching} = useGetFacilityByIdQuery(facilityId, {skip: !facilityId});
    const [updateFacility, {error: mutationError}] = useUpdateFacilityMutation();

    const [selectedDenominations, setSelectedDenominations] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedFacilityTypes, setSelectedFacilityTypes] = useState([]);
    const [donationBased, setDonationBased] = useState("");
    const [bookable, setBookable] = useState("");

    // const [title, setTitle] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [costForHousing, setCostForHousing] = useState(0);
    const [generalError, setGeneralError] = React.useState(null);
    const [message, setMessage] = React.useState(null);

    useEffect(() => {
        if (!facilityData) return;
        setSelectedDenominations(prev => facilityData.denominations.map(d => d._id))
        setSelectedGenders(prev => facilityData.genders.map(g => g._id))
        setSelectedFacilityTypes(prev => facilityData.facilityTypes.map(ft => ft._id))
        setDonationBased(prev => facilityData.donationBased)
        setBookable(prev => facilityData.bookable)

        setTitle(prev => facilityData.title)
        setDescription(prev => facilityData.description)
        setAddress(prev => facilityData.address)
        setTelephone(prev => facilityData.telephone)
        setEmail(prev => facilityData.email)
        setWebsite(prev => facilityData.website)
        setCostForHousing(prev => facilityData.costForHousing)
    }, [facilityData]);

    const [errors, setErrors] = useState({
        title: false,
        description: false,
        address: false,
        telephone: false,
        email: false,
        website: false,
        costForHousing: false,
        denominations: false,
        genders: false,
        facilityTypes: false,
        donationBased: false,
        bookable: false,
    });

    const validateForm = () => {
        const newErrors = {
            title: !title || title === "",
            address: !address || address === "",
            telephone: !telephone || telephone === "",
            email: !email || email === "",
            denominations: selectedDenominations.length === 0,
            facilityTypes: selectedFacilityTypes.length === 0,
            donationBased: donationBased === "",
            bookable: bookable === "",
        };

        setErrors(newErrors);
        // Return true if no errors (every value in newErrors is false)
        return Object.values(newErrors).every(error => !error);
    };

    const handleInputChange = setter => event => {
        setter(event.target.value);
    };

    // Specialized handler for numeric inputs
    const handleNumericChange = setter => event => {
        const value = parseInt(event.target.value, 10);
        setter(!isNaN(value) ? value : '');
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = {
            _id: facilityId,
            title,
            description,
            address,
            telephone,
            email,
            website,
            costForHousing,
            denominations: selectedDenominations,
            genders: selectedGenders,
            facilityTypes: selectedFacilityTypes,
            donationBased,
            bookable
        };

        try {
            const updateFacilityData = await updateFacility({facilityId, facilityData: formData});
            if (updateFacilityData.error) {
                console.log(`error: ${JSON.stringify(updateFacilityData.error)}`)
                setGeneralError(updateFacilityData.error.data.message);
                setMessage(null);
            } else {
                setGeneralError(null);
                setMessage('Facility updated successfully!');
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    };

    const renderErrorMessage = (fieldName) => {
        if (errors[fieldName]) {
            return <Box
                sx={{
                    color: "#db4f4a",
                    marginLeft: "2rem",
                }}
            >
                {errorMessages[fieldName]}
            </Box>;
        }
        return null;
    };

    return (
        <>
            <Navbar title="Edit Facility Page"/>

            {isFetching ? (
                <Box className="loading-bar">
                    <CircularProgress/>
                </Box>
            ) : (
                <StyledFormBox>
                    {/* Attach onChange and value to each StyledInputWithoutForm */}
                    <StyledInputWithoutForm
                        label="Facility Title"
                        value={title}
                        onChange={handleInputChange(setTitle)}
                        errorMessage={renderErrorMessage("title")}
                    />

                    <StyledInputWithoutForm
                        label="Description"
                        value={description}
                        onChange={handleInputChange(setDescription)}
                        errorMessage={renderErrorMessage("description")}
                    />
                    {/* Repeat for other inputs */}
                    <StyledInputWithoutForm
                        label="Facility Address"
                        value={address}
                        onChange={handleInputChange(setAddress)}
                        errorMessage={renderErrorMessage("address")}
                    />
                    <StyledInputWithoutForm
                        label="Telephone"
                        value={telephone}
                        onChange={handleInputChange(setTelephone)}
                        errorMessage={renderErrorMessage("telephone")}
                    />
                    <StyledInputWithoutForm
                        label="Email"
                        value={email}
                        onChange={handleInputChange(setEmail)}
                        errorMessage={renderErrorMessage("email")}
                    />
                    <StyledInputWithoutForm
                        label="Website link"
                        value={website}
                        onChange={handleInputChange(setWebsite)}
                        errorMessage={renderErrorMessage("website")}
                    />
                    <StyledInputWithoutForm
                        label="Cost For Housing"
                        value={costForHousing}
                        type="number"
                        inputProps={{type: 'number'}}
                        onChange={handleNumericChange(setCostForHousing)}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        errorMessage={renderErrorMessage("costForHousing")}
                    />
                    <MultipleSelectMenu
                        label={"Denomination"}
                        values={denominations}
                        selectedValues={selectedDenominations}
                        setSelectedValues={setSelectedDenominations}
                    />
                    {renderErrorMessage("denominations")}
                    <MultipleSelectMenu
                        label={"Genders"}
                        values={genders}
                        selectedValues={selectedGenders}
                        setSelectedValues={setSelectedGenders}
                    />
                    {renderErrorMessage("genders")}
                    <MultipleSelectMenu
                        label={"Facility Types"}
                        values={facilityTypes}
                        selectedValues={selectedFacilityTypes}
                        setSelectedValues={setSelectedFacilityTypes}
                    />
                    {renderErrorMessage("facilityTypes")}
                    <SelectMenu
                        label={"Donation Based"}
                        values={[{name: "Yes", value: true}, {name: "No", value: false}]}
                        selectedValue={donationBased}
                        setSelectedValue={setDonationBased}
                    />
                    {renderErrorMessage("donationBased")}
                    <SelectMenu
                        label={"Bookable for External Retreat Leaders"}
                        values={[{name: "Yes", value: true}, {name: "No", value: false}]}
                        selectedValue={bookable} setSelectedValue={setBookable}
                    />
                    {renderErrorMessage("bookable")}
                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            variant="contained"
                            sx={{fontWeight: "600"}}
                        >
                            Update
                        </Button>
                    </Box>
                    {message && (
                        <Typography
                            variant="h4"
                            color="#4cceac"
                            mt="15px"
                        >
                            {message}
                        </Typography>
                    )}
                    {generalError && (
                        <Typography
                            variant="h4"
                            color="#db4f4a"
                            mt="15px"
                        >
                            {generalError}
                        </Typography>
                    )}
                </StyledFormBox>
            )}
        </>
    )
}