import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styles from "../index.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function SelectMenu({label, values, selectedValue, setSelectedValue}) {
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <FormControl
            fullWidth={true}
            sx={{
                m: 1,
                width: "25rem",
            }}>
            <InputLabel
                id={`${label}-select-label-id`}
                className={styles["select-input-label"]}
            >
                {label}
            </InputLabel>
            <Select
                labelId={`${label}-select-label-id`}
                autoWidth={true}
                id={`${label}-select`}
                value={selectedValue}
                label={label}
                onChange={handleChange}
                MenuProps={MenuProps}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {values.map(({name, value}, index) => {
                    return (
                        <MenuItem key={name} value={value}>{name}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
}