import './styles.css';
import "leaflet/dist/leaflet.css";
import {Routes, Route, Link} from "react-router-dom";
import NotFoundPage from "./scenes/Technical/NotFoundPage";
import AllFacilitiesMapPage from "./scenes/Facility/AllFacilitiesMapPage";
import Layout from "./components/Layout";
import RequireAuth from "./hoc/RequireAuth";
import MyFacilityPage from "./scenes/Facility/MyFacilityPage";
import AllFacilitiesPage from "./scenes/Facility/AllFacilitiesPage";
import SingleFacilityPage from "./scenes/Facility/SingleFacilityPage";
import EditFacilityPage from "./scenes/Facility/EditFacilityPage";
import AllEventsPage from "./scenes/Event/AllEventsPage";
import MyEventsPage from "./scenes/Event/MyEventsPage";
import SingleEventPage from "./scenes/Event/SingleEventPage";
import AboutPage from "./scenes/Technical/AboutPage";
import EditEventPage from "./scenes/Event/EditEventPage";
import CreateEventPage from "./scenes/Event/CreateEventPage";
import LoginPage from "./scenes/Technical/LoginPage";
import SignUpPage from "./scenes/Technical/SignUp";
import PrivacyPolicy from "./scenes/Technical/PrivacyPolicy";
import Impressum from "./scenes/Technical/Impressum";
import CreateFacilityPage from "./scenes/Facility/CreateFacilityPage";

function App() {

    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    {/*Facility*/}
                    <Route index element={<AllFacilitiesMapPage/>}/>
                    <Route path="allFacilities" element={<AllFacilitiesPage/>}/>
                    <Route path="myFacility" element={
                        <RequireAuth>
                            <MyFacilityPage/>
                        </RequireAuth>
                    }/>
                    <Route path="facility/:id" element={<SingleFacilityPage/>}/>
                    <Route path="facility/editFacility/:id" element={
                        <RequireAuth>
                            <EditFacilityPage/>
                        </RequireAuth>
                    }/>
                    <Route path="facility/newFacility" element={
                        <RequireAuth>
                            <CreateFacilityPage/>
                        </RequireAuth>
                    }/>

                    {/*Event*/}
                    <Route path="allEvents" element={<AllEventsPage/>}/>
                    <Route path="myEvents" element={
                        <RequireAuth>
                            <MyEventsPage/>
                        </RequireAuth>
                    }/>
                    <Route path="event/:id" element={<SingleEventPage/>}/>
                    <Route path="event/editEvent/:id" element={
                        <RequireAuth>
                            <EditEventPage/>
                        </RequireAuth>
                    }/>
                    <Route path="event/newEvent" element={
                        <RequireAuth>
                            <CreateEventPage/>
                        </RequireAuth>
                    }/>
                    <Route path="event/newEvent" element={
                        <RequireAuth>
                            <CreateEventPage/>
                        </RequireAuth>
                    }/>

                    {/*Technical*/}
                    <Route path="login" element={<LoginPage/>}/>
                    <Route path="signup" element={<SignUpPage/>}/>
                    <Route path="about" element={<AboutPage/>}/>
                    <Route path="impressum" element={<Impressum/>}/>
                    <Route path="privacyPolicy" element={<PrivacyPolicy/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
